<template>
  <div class="cs-panel-default bg-white">
    <div class="cs-panel-header">
      <cs-icon v-if="icon" :name="icon" type="symbol" />
      <a-icon v-else class="back-icon" @click="backClick" type="arrow-left" />
      <h1 class="fw-bold">{{ title }}</h1>
    </div>
    <div class="cs-panel-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    icon: String,
  },
  data() {
    return {};
  },

  methods: {
    backClick() {
      const { back } = this.$listeners;
      if (back) {
        this.$emit("back");
      } else {
        window.history.back();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cs-panel-default {
  height: 100%;
  .cs-panel-header {
    height: 57px;
    line-height: 57px;
    border-bottom: 1px solid #eee;
    padding-left: 25px;
    .cs-icon {
      float: left;
      margin-top: 20px;
      margin-right: 10px;
    }
    h1 {
      font-size: 20px;
      color: #333333;
      display: inline-block;
    }
    .back-icon {
      cursor: pointer;
      border: 1px solid #eee;
      height: 28px;
      width: 28px;
      line-height: 28px;
      text-align: center;
      margin-right: 10px;
      border-radius: 3px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
    }

    .back-icon:hover {
      opacity: 0.6;
    }
  }
}
</style>