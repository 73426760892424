import { render, staticRenderFns } from "./cs-panel-default.vue?vue&type=template&id=04fd53a0&scoped=true&"
import script from "./cs-panel-default.vue?vue&type=script&lang=js&"
export * from "./cs-panel-default.vue?vue&type=script&lang=js&"
import style0 from "./cs-panel-default.vue?vue&type=style&index=0&id=04fd53a0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fd53a0",
  null
  
)

export default component.exports